import { GAME_NAME } from "../app_config";
import "../styles/menu.css";
import { useStore } from "../zustand/zStore";

const back_pt = ["Voltar"];

const back_en = ["Back"];

export function Links({ gameStarted }) {
  // const gameOver = useStore((state) => state.gameOver);
  const gameLoaded = true;

  const showMenu = useStore((s) => s.menu);
  const setMenu = useStore((s) => s.setMenu);

  const showLinks = useStore((s) => s.links);
  const setLinks = useStore((s) => s.setLinks);

  return (
    <div className={`info show `} >
      <div className={"column_container"}>
        <div className="name_game_info ">{GAME_NAME}</div>
        {/* <button
          name="B8"
          className={"menu_button"}
          onClick={() => {
            setLinks(!showLinks);
            setMenu(!showMenu);
          }}
        >
          Menu
        </button> */}
        <button
          onClick={() => {
            setLinks(!showLinks);
            setMenu(!showMenu);
          }}
          className={"play_button_info"}
        >
          {back_en.slice(0, 1)}
        </button>
        <p>
          <a
            href="https://resolution.pt/"
            target="_blank"
            rel="noreferrer"
            title="Resolution - Consultores Informáticos, Lda"
          >
            <i>Resolution - Consultores Informáticos, Lda.</i>
          </a>
        </p>
        </div>
        <div className="links">
        THREEJS&nbsp;&nbsp;
        <a
          href="https://threejs.org/"
          target="_blank"
          rel="noreferrer"
          title="threejs"
        >
          <i>https://threejs.org/</i>
        </a>
        <br />
        CANNON&nbsp;&nbsp;
        <a
          href="https://github.com/pmndrs/use-cannon"
          target="_blank"
          rel="noreferrer"
          title="use-cannon"
        >
          <i>https://github.com/pmndrs/use-cannon</i>
        </a>
        <br />
        DREI&nbsp;&nbsp;
        <a
          href="https://github.com/pmndrs/drei"
          target="_blank"
          rel="noreferrer"
          title="drei"
        >
          <i>https://github.com/pmndrs/drei</i>
        </a>
        <br />
        FIBER&nbsp;&nbsp;
        <a
          href="https://docs.pmnd.rs/react-three-fiber/getting-started/introduction"
          target="_blank"
          rel="noreferrer"
          title="fiber"
        >
          <i>
            https://docs.pmnd.rs/react-three-fiber
          </i>
        </a>
        <br />
        RAPIER&nbsp;&nbsp;
        <br />
        <a
          href="https://github.com/pmndrs/react-three-rapier"
          target="_blank"
          rel="noreferrer"
          title="rapier"
        >
         <i>https://github.com/pmndrs/react-three-rapier</i>
        </a>
        <br />
        <a
          href="https://react-three-rapier.pmnd.rs/all-colliders"
          target="_blank"
          rel="noreferrer"
          title="rapier demos"
        >
         <i>https://react-three-rapier.pmnd.rs/all-colliders</i>
        </a>
        <br />
        LEVA&nbsp;&nbsp;
        <a
          href="https://github.com/pmndrs/leva"
          target="_blank"
          rel="noreferrer"
          title="leva"
        >
         <i>https://github.com/pmndrs/leva</i>
        </a>
        <br />
        ZUSTAND&nbsp;&nbsp;
        <a
          href="https://github.com/pmndrs/zustand"
          target="_blank"
          rel="noreferrer"
          title="zustand"
        >
          <i>https://github.com/pmndrs/zustand</i>
        </a>
        <br />
        REACT-DEVICE-DETECT&nbsp;&nbsp;
        <a
          href="https://www.npmjs.com/package/react-device-detect"
          target="_blank"
          rel="noreferrer"
          title="Device Detect"
        >
          <i>
          https://www.npmjs.com/package/react-device-detect
          </i>
        </a>
        <br />
        GLTF&nbsp;&nbsp;
        <a
          href="https://sbcode.net/react-three-fiber/use-gltf/"
          target="_blank"
          rel="noreferrer"
          title="USE-GLTF"
        >
          <i>
          https://sbcode.net/react-three-fiber/use-gltf/
          </i>
        </a>
        <br />
        GLTFJSX&nbsp;&nbsp;
        <a
          href="https://sbcode.net/react-three-fiber/gltfjsx/"
          target="_blank"
          rel="noreferrer"
          title="GLTFJSX"
        >
          <i>
          https://sbcode.net/react-three-fiber/gltfjsx/
          </i>
        </a>
        <br />
        EDITOR.SBCODE.NET&nbsp;&nbsp;
        <a
          href="https://editor.sbcode.net/035deb31bca29a66ab05fb11cc0eb06cfef64c58"
          target="_blank"
          rel="noreferrer"
          title="SBCODE"
        >
          <i>
          https://editor.sbcode.net
          </i>
        </a>
        <br />
        PlayRoom&nbsp;&nbsp;
        <a
          href="https://joinplayroom.com/"
          target="_blank"
          rel="noreferrer"
          title="PLAYROOM"
        >
          <i>
          https://joinplayroom.com/
          </i>
        </a>
        <br />
        <a
          href="https://reactjsexample.com/a-simple-three-js-game-of-how-does-react-work-together-with-3d-graphics-in-three-js/"
          target="_blank"
          rel="noreferrer"
          title="simple three.js game"
        >
          <i>
            A simple three.js game of how does React work together with 3D
            graphics in three.js
          </i>
        </a>
        <br />
        <a
          href="https://codesandbox.io/p/sandbox/pinball-in-70-lines-rmfcq"
          target="_blank"
          rel="noreferrer"
          title="Pinball"
        >
          <i>Pinball in 70 lines (sandbox)</i>
        </a>
        <br />
          <a
            href="https://codesandbox.io/s/react-three-fiber-spaceship-game-dx9ow"
            target="_blank"
            rel="noreferrer"
            title="Space Ship"
          >
            <i>3D spaceship shooting game (sandbox)</i>
          </a>
      </div>
    </div>
  );
}
