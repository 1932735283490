// import { createRef } from "react";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { mountStoreDevtool } from "simple-zustand-devtools";

const useStore = create(
  persist(
    (set, get) => ({
      gameOver: false,
      gameStarted: false,
      loaded: false,
      music: false,
      menu: true,
      info: false,
      links: false,
      howto: false,
      sounds: false,
      voice: false,
      warehouse: false,
      training : false,
      level: "1",
      lang: "PT",
      setGameOver: (gameOver) => set({ gameOver }),
      setGameStarted: (gameStarted) => set((state) => ({ gameStarted })),
      setLoaded: (loaded) => set((state) => ({ loaded })),
      setMusic: (music) => set((state) => ({ music })),
      setInfo: (info) => set((state) => ({ info })),
      setMenu: (menu) => set((state) => ({ menu })),
      setLinks: (links) => set((state) => ({ links })),
      setHowTo: (howto) => set((state) => ({ howto })),
      setWarehouse: (warehouse) => set((state) => ({ warehouse })),
      setSounds: (sounds) => set((state) => ({ sounds })),
      setVoice: (voice) => set((state) => ({ voice })),
      setLang: (lang) => set((state) => ({ lang })),
      setLevel: (level) => set((state) => ({ level })),
      setTraining: (training) => set((state) => ({ training })),
    }),
    {
      partialize: (state) => ({
        sounds: state.sounds,
        voice: state.voice,
        warehouse: state.warehouse,
        lang: state.lang,
        level: state.level,
      }),
    }
  )
);

// const useStore = create((set) => ({
//   gameOver: false,
//   gameStarted: false,
//   loaded: false,
//   defaults: false,
//   music: false,
//   sounds: false,
//   voice: false,
//   warehouse: false,
//   dark: true,
//   menu: true,
//   info: false,
//   links: false,
//   lang: 'PT',
//   howto: false,
//   setGameOver: (gameOver) => set({ gameOver }),
//   setGameStarted: (gameStarted) => set((state) => ({ gameStarted })),
//   setLoaded: (loaded) => set((state) => ({ loaded })),
//   setDefaults: (defaults) => set((state) => ({ defaults })),
//   setDark: (dark) => set((state) => ({ dark })),
//   setWarehouse: (warehouse) => set((state) => ({ warehouse })),
//   setMusic: (music) => set((state) => ({ music })),
//   setSounds: (sounds) => set((state) => ({ sounds })),
//   setVoice: (voice) => set((state) => ({ voice })),
//   setInfo: (info) => set((state) => ({ info })),
//   setMenu: (menu) => set((state) => ({ menu })),
//   setLinks: (links) => set((state) => ({ links })),
//   setLang: (lang) => set((state) => ({ lang })),
//   setHowTo: (howto) => set((state) => ({ howto })),
// }));

const data = {
  points: 0,
  goals: 0,
  goalsx: 0,
  collisions: 0,
  level: 1,
};

// mountStoreDevtool("STORE", useStore as any);
mountStoreDevtool("STORE", useStore);

export { useStore, data };
