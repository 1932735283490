import * as THREE from "three";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { Suspense } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
// import { useThree } from "@react-three/fiber";
import {
  PerspectiveCamera,
  RoundedBox,
  Environment,
  useTexture,
  useAspect,
  Preload,
} from "@react-three/drei";
import { Physics, useSphere, useBox, usePlane } from "@react-three/cannon";
import * as rdd from "react-device-detect";
import { useStore, data } from "./zustand/zStore";
// import Ship from './Ship'
// import Rig from './Rig'
import { Dom } from "./dom/Dom";
import { Placard } from "./Placard";
import { GameLoader } from "./GameLoader";
import ohNoSound from "./audio/oh_no.mp3";
import ohYesSound from "./audio/yes.mp3";
import pingSound from "./audio/ping.mp3";
import clickSound from "./audio/click.mp3";
import moveSound from "./audio/laser.wav";
import jumpSound from "./audio/laser.mp3";

let env = "warehouse";
let environ = "warehouse";
let dark_on = true;
let sound_on = false;
let voice_on = false;
let training_on = false;
let level_num = "";

let Mb1 = 0;
let Mb2 = 0;
let Mb3 = 0;
let Pd = 0;
let Gk = 0;
let pdl = 0;
let Lim = 0;
let LimI = 0;
let LimS = 0;
let RestB = 0;
let RestP = 0;
let RestH = 0;
let RestW = 0;

const Params = () => {
  if (rdd.isMobile) {
    Mb1 = 3;
    Mb2 = 10;
    Mb3 = 18;
    Pd = 12;
    Gk = 5;
    pdl = 4;
    Lim = 10;
    LimI = 20;
    LimS = 21;
    RestB = 3;
    RestP = 3;
    RestH = 1.5;
    RestW = 0;
  } else if (rdd.isTablet) {
    Mb1 = 14;
    Mb2 = 14;
    Mb3 = 18;
    Pd = 14;
    Gk = 20;
    pdl = 5;
    Lim = 15;
    LimI = 19;
    LimS = 20;
    RestB = 7;
    RestP = 5;
    RestH = 3;
    RestW = 0;
    {
      !training_on ? (Gk = 35) : null;
    }
  } else {
    Mb1 = 14;
    Mb2 = 14;
    Mb3 = 18;
    Pd = 14;
    Gk = 20;
    pdl = 5;
    Lim = 15;
    LimI = 19;
    LimS = 20;
    RestB = 10;
    RestP = 3;
    RestH = 3;
    RestW = 0;
    {
      !training_on ? (Gk = 35) : null;
    }
  }
};

const parameters = {
  color: 0xffffff,
  transmission: 1,
  opacity: 1,
  metalness: 0,
  roughness: 0,
  ior: 1.5,
  thickness: 0.01,
  specularIntensity: 1,
  specularColor: 0xffffff,
  envMapIntensity: 1,
  lightIntensity: 1,
  exposure: 1,
};

const ping = new Audio(pingSound);

const Defense = () => {
  if (sound_on) {
    ping.play();
  }
};

const jump = new Audio(jumpSound);

const Jump = () => {
  if (sound_on) {
    jump.play();
  }
};

const move = new Audio(moveSound);

const Move = () => {
  if (sound_on) {
    move.play();
  }
};

const ohNo = new Audio(ohNoSound);
const ohYes = new Audio(ohYesSound);

const GoalX = () => {
  if (voice_on) {
    ohNo.play();
  }
  data.goalsx += 1;
};

const Goal = () => {
  if (voice_on) {
    ohYes.play();
  }
  data.goals += 1;
};

const xclick = new Audio(clickSound);

const Collision = () => {
  if (sound_on) {
    xclick.play();
  }
  data.points += 1;
};

// BallAndCollisions !!!!!!!!!!!!!!!!!!!
function BallAndCollisions({
  // shake = 0,
  args = [1.2, 32, 32],
  v = new THREE.Vector3(),
}) {
  const cam = useRef();

  const [ball, api] = useSphere(() => ({
    args: [1.2, 32, 32],
    mass: 1,
    material: { restitution: 0.95 },
  }));

  // const state = useThree()

  usePlane(() => ({
    position: [0, LimS, 0],
    rotation: [Math.PI / 2, 0, 0],
    onCollide: () => api.velocity.set(10, 50, 0),
  }));

  usePlane(() => ({
    position: [0, -LimI, 0],
    rotation: [-Math.PI / 2, 0, 0],
    onCollide: () => api.velocity.set(10, 50, 0),
  }));

  usePlane(() => ({ position: [-Lim, 0, 0], rotation: [0, Math.PI / 2, 0] }));

  usePlane(() => ({ position: [Lim, 0, 0], rotation: [0, -Math.PI / 2, 0] }));

  const [goal1] = useBox(() => ({
    args: [7, 0, 0],
    position: [0, LimS - 0.5, 0],
    onCollide: () => (
      api.position.set(0, 0, 0), api.velocity.set(0, 0, 0), Goal()
    ),
  }));
  const [goal2] = useBox(() => ({
    args: [7, 0, 0],
    position: [0, -(LimI - 0.5), 0],
    onCollide: () => (
      api.position.set(0, 0, 0), api.velocity.set(0, 0, 0), GoalX()
    ),
  }));

  useEffect(
    () =>
      api.position.subscribe(
        (p) => (
          cam.current.position.lerp(
            v.set(p[0], p[1], LimS + Math.max(0, p[1]) / 2),
            0
          ),
          cam.current.lookAt(0, 0, 0)
        )
      ),
    []
  );

  return (
    <>
      <PerspectiveCamera
        ref={cam}
        makeDefault
        position={[0, 0, 12]}
        fov={120}
      />
        <mesh
          ref={ball}
          name="ball"
          onClick={(ev) => {
            ev.nativeEvent.preventDefault();
              api.position.set(0, 15, 0), api.velocity.set(0, 0, 0);
              Move();
          }}
        >
          <sphereGeometry args={args} />
          <meshPhysicalMaterial
            map={useTexture("/star.jpg")}
            transmission={1}
            roughness={0}
            thickness={10}
            envMapIntensity={1}
          />
        </mesh>
      <RoundedBox ref={goal1} args={[7, 0, 0]} radius={0.1} smoothness={10}>
        <meshPhysicalMaterial
          transmission={1}
          roughness={0}
          thickness={3}
          envMapIntensity={4}
        />
      </RoundedBox>
      <RoundedBox ref={goal2} args={[7, 0, 0]} radius={0.1} smoothness={10}>
        <meshPhysicalMaterial
          transmission={1}
          roughness={0}
          thickness={3}
          envMapIntensity={4}
        />
      </RoundedBox>
    </>
  );
}

function BallAndCollisionsD({ args = [1.2, 32, 32], v = new THREE.Vector3() }) {
  const cam = useRef();
  const [ref, api] = useSphere(() => ({
    args: [1.2],
    mass: 1,
    material: { restitution: 0.95 },
  }));
  usePlane(() => ({
    position: [0, -Lim, 0],
    rotation: [-Math.PI / 2, 0, 0],
    onCollide: () => (api.position.set(0, 0, 0), api.velocity.set(0, 0, 0)),
  }));
  usePlane(() => ({
    position: [-Lim, 0, 0],
    rotation: [-Math.PI / 2, Math.PI / 2, 0],
  }));
  usePlane(() => ({
    position: [Lim, 0, 0],
    rotation: [Math.PI / 2, -Math.PI / 2, 0],
  }));
  useEffect(
    () =>
      api.position.subscribe(
        (p) => (
          cam.current.position.lerp(
            v.set(p[0], p[1], Lim + 3 + Math.max(0, p[1]) / 2),
            0.05
          ),
          cam.current.lookAt(0, 0, 0)
        )
      ),
    []
  );
  return (
    <>
      <PerspectiveCamera ref={cam} makeDefault position={[0, 0, 12]} fov={50} />
      <mesh ref={ref}>
        <sphereGeometry args={args} />
        <meshPhysicalMaterial
          map={useTexture("/star.jpg")}
          transmission={1}
          roughness={0}
          thickness={10}
          envMapIntensity={1}
        />
      </mesh>
    </>
  );
}

function Ground() {
  // const [state, set] = useState(false)
  usePlane(() => ({
    position: [0, 0, -1],
    rotation: [0, 0, 0],
    material: "ground",
    type: "Static",
  }));
  return <mesh />;
}

const Block = forwardRef(
  (
    { shake = 0, args = [1, 1.5, 4], vec = new THREE.Vector3(), ...props },
    ref
  ) => {
    const group = useRef();
    const [block, api] = useBox(() => ({
      args,
      ...props,
      onCollide: (e) => (
        (shake += e.contact.impactVelocity / 12.5), Collision()
      ),
    }));
    useFrame(() =>
      group.current.position.lerp(
        vec.set(0, (shake = THREE.MathUtils.lerp(shake, 0, 0.1)), 0),
        0.2
      )
    );
    useImperativeHandle(ref, () => api, [api]);
    return (
      <group ref={group}>
        <RoundedBox ref={block} args={args} radius={0.4} smoothness={10}>
          <meshPhysicalMaterial
            transmission={1}
            roughness={0}
            thickness={3}
            envMapIntensity={4}
          />
        </RoundedBox>
      </group>
    );
  }
);

const BlockGk = forwardRef(
  (
    { shake = 0, args = [1, 1.5, 4], vec = new THREE.Vector3(), ...props },
    ref
  ) => {
    const group = useRef();
    const [block, api] = useBox(() => ({
      args,
      ...props,
      onCollide: (e) => ((shake += e.contact.impactVelocity / 12.5), Defense()),
    }));
    useFrame(() =>
      group.current.position.lerp(
        vec.set(0, (shake = THREE.MathUtils.lerp(shake, 0, 0.1)), 0),
        0.2
      )
    );
    useImperativeHandle(ref, () => api, [api]);
    return (
      <group ref={group}>
        <RoundedBox ref={block} args={args} radius={0.4} smoothness={10}>
          <meshPhysicalMaterial
            transmission={1}
            roughness={0}
            thickness={3}
            envMapIntensity={4}
          />
        </RoundedBox>
      </group>
    );
  }
);

const BlockS = forwardRef(
  (
    { shake = 0, args = [1, 1.5, 4], vec = new THREE.Vector3(), ...props },
    ref
  ) => {
    const group = useRef();
    const [block, api] = useBox(() => ({
      args,
      ...props,
      onCollide: (e) => (shake -= e.contact.impactVelocity / 12.5),
    }));
    useFrame(() =>
      group.current.position.lerp(
        vec.set(0, (shake = THREE.MathUtils.lerp(shake, 0, 0.1)), 0),
        0.2
      )
    );
    useImperativeHandle(ref, () => api, [api]);
    return (
      <group ref={group}>
        <RoundedBox ref={block} args={args} radius={0.4} smoothness={10}>
          <meshPhysicalMaterial
            transmission={1}
            roughness={0}
            thickness={3}
            envMapIntensity={4}
          />
        </RoundedBox>
      </group>
    );
  }
);

const BlockP = forwardRef(
  (
    { shake = 0, args = [1, 1.5, 4], vec = new THREE.Vector3(), ...props },
    ref
  ) => {
    const group = useRef();
    const [block, api] = useBox(() => ({
      args,
      ...props,
      onCollide: (e) => (shake += e.contact.impactVelocity / 12.5),
    }));
    useFrame(() =>
      group.current.position.lerp(
        vec.set(0, (shake = THREE.MathUtils.lerp(shake, 0, 0.1)), 0),
        0.2
      )
    );
    useImperativeHandle(ref, () => api, [api]);
    return (
      <group ref={group}>
        <RoundedBox ref={block} args={args} radius={0.4} smoothness={10}>
          <meshPhysicalMaterial
            transmission={1}
            roughness={0}
            thickness={3}
            envMapIntensity={4}
          />
        </RoundedBox>
      </group>
    );
  }
);

const BlockD = forwardRef(
  (
    { shake = 0, args = [1, 1.5, 4], vec = new THREE.Vector3(), ...props },
    ref
  ) => {
    const group = useRef();
    const [block, api] = useBox(() => ({
      args,
      ...props,
      onCollide: (e) => (shake += e.contact.impactVelocity / 12.5),
    }));
    useFrame(() =>
      group.current.position.lerp(
        vec.set(0, (shake = THREE.MathUtils.lerp(shake, 0, 0.1)), 0),
        0.2
      )
    );
    useImperativeHandle(ref, () => api, [api]);
    return (
      <group ref={group}>
        <RoundedBox ref={block} args={args} radius={0.4} smoothness={10}>
          <meshPhysicalMaterial
            transmission={1}
            roughness={0}
            thickness={3}
            envMapIntensity={4}
          />
        </RoundedBox>
      </group>
    );
  }
);

const FireRight = ({ group }) => (
  <mesh
    name="right"
    scale={[3, 3, 1]}
    position={[10, -13, 0]}
    onContextMenu={(ev) => {
      ev.nativeEvent.preventDefault();
      if (ev.eventObject.name === "right") {
        group.current.position.set(10, 19, 0);
        Jump();
      }
    }}
  >
    <sphereGeometry args={[0.8]} />
    <meshPhysicalMaterial
      map={useTexture("/jump_r.jpg")}
      transmission={1}
      roughness={0}
      thickness={5}
      envMapIntensity={1}
    />
  </mesh>
);

const FireLeft = ({ group }) => (
  <mesh
    name="left"
    scale={[3, 3, 1]}
    position={[-10, -13, 0]}
    onContextMenu={(ev) => {
      ev.nativeEvent.preventDefault();
      if (ev.eventObject.name === "left") {
        group.current.position.set(-10, 19, 0);
        Jump();
      }
    }}
  >
    <sphereGeometry args={[0.8]} />
    <meshPhysicalMaterial
      map={useTexture("/jump.jpg")}
      transmission={1}
      roughness={0}
      thickness={5}
      envMapIntensity={1}
    />
  </mesh>
);

const BlockPad = forwardRef(
  (
    { shake = 2, args = [1, 1.5, 4], vec = new THREE.Vector3(), ...props },
    ref
  ) => {
    const pad = useRef();
    const group = useRef();
    const [block, api] = useBox(() => ({
      args,
      ...props,
      onCollide: (e) => ((shake += e.contact.impactVelocity / 12.5), Defense()),
    }));
    useFrame(() =>
      group.current.position.lerp(
        vec.set(0, (shake = THREE.MathUtils.lerp(shake, 0, 0.1)), 0),
        0.2
      )
    );

    useImperativeHandle(ref, () => api, [api]);

    // {rdd.isDesktop ? <FireLeft group={group} /> : null}

    if (rdd.isDesktop) {
      return (
        <group ref={group}>
          <mesh
            name="pad"
            onPointerMissed={(e) => {
              group.current.position.set(group.current.position.x, 19, 0);
              Jump();
            }}
            onContextMenu={(ev) => {
              ev.nativeEvent.preventDefault();
              if (ev.eventObject.name === "pad") {
                group.current.position.set(group.current.position.x, 19, 0);
                Jump();
              }
            }}
          >
            <RoundedBox ref={block} args={args} radius={0.4} smoothness={10}>
              <meshPhysicalMaterial
                transmission={1}
                roughness={0}
                thickness={3}
                envMapIntensity={4}
              />
            </RoundedBox>
          </mesh>
        </group>
      );
    } else {
      return (
        <group ref={group}>
          <mesh
            name="pad"
            // onPointerMissed={(e) => {
            //   group.current.position.set(group.current.position.x, 19, 0);
            //   Jump();
            // }}
            onContextMenu={(ev) => {
              ev.nativeEvent.preventDefault();
              if (ev.eventObject.name === "pad") {
                group.current.position.set(group.current.position.x, 19, 0);
                Jump();
              }
            }}
          >
            <RoundedBox ref={block} args={args} radius={0.4} smoothness={10}>
              <meshPhysicalMaterial
                transmission={1}
                roughness={0}
                thickness={3}
                envMapIntensity={4}
              />
            </RoundedBox>
          </mesh>
        </group>
      );
    }
  }
);

function Paddle({ args = [pdl, 1.5, 1.5] }) {
  const group = useRef();
  const api = useRef();
  useFrame(
    (state) => (
      api.current.position.set(state.pointer.x * Pd, -(LimI - 2), 0),
      api.current.rotation.set(0, 0, (state.pointer.x * Math.PI) / Pd)
    )
  );
  return <BlockPad ref={api} args={args} material={{ restitution: RestP }} />;
}
function PaddleD({ args = [5, 1.5, 4] }) {
  const api = useRef();
  // useFrame((state) => (api.current.position.set(state.pointer.x * 10, -5, 0), api.current.rotation.set(0, 0, (state.pointer.x * Math.PI) / 4)))
  useFrame(
    (state) => (
      api.current.position.set(0, -5, 0), api.current.rotation.set(0, 0, 0)
    )
  );
  return <Block ref={api} args={args} material={{ restitution: 1.3 }} />;
}

function MovingBlock({ offset = 0, position: [x, y, z], ...props }) {
  const api = useRef();
  useFrame((state) =>
    api.current.position.set(
      x +
        (Math.sin(offset + state.clock.elapsedTime) * state.viewport.width) /
          Mb1,
      y,
      z
    )
  );
  return (
    <Block
      ref={api}
      args={[3, 1.5, 4]}
      material={{ restitution: RestB }}
      {...props}
    />
  );
}

function MovingBlockD({ offset = 0, position: [x, y, z], ...props }) {
  const api = useRef();
  useFrame((state) =>
    api.current.position.set(
      x +
        (Math.sin(offset + state.clock.elapsedTime) * state.viewport.width) /
          Mb2,
      y,
      z
    )
  );
  return (
    <BlockD
      ref={api}
      args={[3, 1.5, 4]}
      material={{ restitution: RestB }}
      {...props}
    />
  );
}

function MovingBlockV({ offset = 0, position: [x, y, z], ...props }) {
  const api = useRef();
  useFrame((state) =>
    api.current.position.set(
      x,
      y +
        (Math.sin(offset + state.clock.elapsedTime) * state.viewport.width) /
          Mb2,
      z
    )
  );
  // return <Block ref={api} args={[3, 1.5, 4]} material={{ restitution: 1.1 }} {...props} />
  return (
    <Block
      ref={api}
      args={[3, 1.5, 4]}
      material={{ restitution: RestB }}
      {...props}
    />
  );
}

function MovingBlockHx({ offset = 0, position: [x, y, z], ...props }) {
  const api = useRef();
  useFrame((state) =>
    api.current.position.set(
      x +
        (Math.sin(offset + state.clock.elapsedTime) * state.viewport.width) /
          Mb2,
      y,
      z
    )
  );
  // return <Block ref={api} args={[3, 1.5, 4]} material={{ restitution: 1.1 }} {...props} />
  return (
    <Block
      ref={api}
      args={[3, 1, 1]}
      material={{ restitution: RestB }}
      {...props}
    />
  );
}

function MovingBlockHy({ offset = 0, position: [x, y, z], ...props }) {
  const api = useRef();
  useFrame((state) =>
    api.current.position.set(
      x,
      y +
        (Math.sin(offset + state.clock.elapsedTime) * state.viewport.width) /
          Mb2,
      z
    )
  );
  // return <Block ref={api} args={[3, 1.5, 4]} material={{ restitution: 1.1 }} {...props} />
  return (
    <Block
      ref={api}
      args={[3, 1, 1]}
      material={{ restitution: RestB }}
      {...props}
    />
  );
}

function MovingBlock3d({ offset = 0, position: [x, y, z], ...props }) {
  const api = useRef();
  useFrame((state) =>
    api.current.position.set(
      x,
      y,
      z +
        (Math.sin(offset + state.clock.elapsedTime) * state.viewport.width) /
          Mb3
    )
  );
  // return <Block ref={api} args={[3, 1.5, 4]} material={{ restitution: 1.1 }} {...props} />
  return (
    <Block
      ref={api}
      args={[3, 1.5, 4]}
      material={{ restitution: RestB }}
      {...props}
    />
  );
}

function GoalKeeper({ offset = 0, position: [x, y, z], ...props }) {
  const api = useRef();
  useFrame((state) =>
    api.current.position.set(
      x +
        (Math.sin(offset + state.clock.elapsedTime) * state.viewport.width) /
          Gk,
      y,
      z
    )
  );
  return (
    <BlockGk
      ref={api}
      args={[2, 1, 0.5]}
      material={{ restitution: RestB }}
      {...props}
    />
  );
}

function GoalKeeperT({ offset = 0, position: [x, y, z], ...props }) {
  const api = useRef();
  useFrame((state) =>
    api.current.position.set(
      x +
        (Math.sin(offset + state.clock.elapsedTime) * state.viewport.width) /
          Gk,
      y,
      z
    )
  );
  return (
    <BlockGk
      ref={api}
      args={[4, 1, 0.5]}
      material={{ restitution: RestB - 2 }}
      {...props}
    />
  );
}

const Background = (props) => (
  <mesh scale={useAspect(5000, 3800, 3)} {...props}>
    <planeGeometry />
    <meshBasicMaterial map={useTexture("/bg3.jpg")} />
  </mesh>
);

function Headers() {
  if (rdd.isMobile) {
    return null;
  } else {
    return (
      <>
        <BlockS
          args={[10, 1, 0]}
          position={[-9.5, -19, 0]}
          rotation={[0, 0, 0]}
          material={{ restitution: RestH }}
        />
        <BlockS
          args={[10, 1, 0]}
          position={[9.5, -19, 0]}
          rotation={[0, 0, 0]}
          material={{ restitution: RestH }}
        />
        <BlockS
          args={[10, 1, 0]}
          position={[-9.5, 20, 0]}
          rotation={[0, 0, 0]}
          material={{ restitution: RestH }}
        />
        <BlockS
          args={[10, 1, 0]}
          position={[9.5, 20, 0]}
          rotation={[0, 0, 0]}
          material={{ restitution: RestH }}
        />
      </>
    );
  }
}

function Walls() {
  if (rdd.isMobile) {
    return null;
  } else {
    return (
      <>
        <BlockS
          args={[1, 19.2, 0]}
          position={[-15, 9.8, 0]}
          rotation={[0, 0, 0]}
          material={{ restitution: RestW }}
        />
        <BlockS
          args={[1, 18.3, 0]}
          position={[-15, -9.2, 0]}
          rotation={[0, 0, 0]}
          material={{ restitution: RestW }}
        />
        <BlockS
          args={[1, 20, 0]}
          position={[15, 10, 0]}
          rotation={[0, 0, 0]}
          material={{ restitution: RestW }}
        />
        <BlockS
          args={[1, 19, 0]}
          position={[15, -9.5, 0]}
          rotation={[0, 0, 0]}
          material={{ restitution: RestW }}
        />
      </>
    );
  }
}

function Level3() {
  if (level_num === "2") {
    return null;
  } else {
    return (
      <>
        <MovingBlockHx key={31} position={[-7, -5, 0]} offset={10000 * 6} />
        <MovingBlock key={32} position={[5, -2, 0]} offset={10000 * 2} />
        <MovingBlockV key={33} position={[6, 10, 0]} offset={10000 * 8} />
        <MovingBlockV key={34} position={[-6, 10, 0]} offset={10000 * 2} />
        <MovingBlockV key={35} position={[-4, -1, 0]} offset={10000 * 4} />
        <MovingBlock3d key={36} position={[10, 0, 0]} offset={10000 * 2} />
      </>
    );
  }
}

function Level2() {
  if (level_num === "1") {
    return null;
  } else {
    return (
      <>
        <MovingBlockHx key={21} position={[-7, -5, 0]} offset={10000 * 6} />
        <MovingBlockV key={22} position={[6, 10, 0]} offset={10000 * 8} />
        <MovingBlockV key={23} position={[-6, 10, 0]} offset={10000 * 2} />
        <MovingBlock3d key={24} position={[-8, 0, 0]} offset={10000 * 1} />
        <Level3 />
      </>
    );
  }
}

function MovingElements() {
  return (
    <>
      <MovingBlock key={1} position={[-5, 5, 0]} offset={10000 * 2} />
      <MovingBlock key={2} position={[5, 5, 0]} offset={10000 * 3} />
      <MovingBlock key={3} position={[-5, 2, 0]} offset={10000 * 5} />

      <MovingBlock key={5} position={[4, 12, 0]} offset={10000 * 2} />

      <MovingBlockHy key={7} position={[-8, 2, 0]} offset={10000 * 3} />
      <MovingBlockHx key={8} position={[0, 14, 0]} offset={10000 * 3} />
      <MovingBlockHx key={71} position={[5, -3, 0]} offset={10000 * 3} />

      <Level2 />
    </>
  );
}

const Game = () => (
  <Physics iterations={5} gravity={[0, -30, -100]}>
    {/* <Debug> */}
    <Ground />
    <BallAndCollisions />
    <Paddle />
    {/* <FireLeft />
    <FireRight /> */}

    {
      Array.from({ length: 0 }, (_, i) => <MovingBlock key={i} position={[0, 1 + i * 4.5, 0]} offset={10000 * i} />) /* prettier-ignore */
    }

    <Headers />
    <Walls />

    {training_on ? (
      <GoalKeeperT key={12} position={[0.5, LimS - 1, 0]} offset={10000 * 1} />
    ) : (
      <>
        <MovingElements />
        <GoalKeeper key={12} position={[0.5, LimS - 1, 0]} offset={10000 * 1} />
      </>
    )}

    {dark_on ? (
      <Environment preset={environ} />
    ) : (
      <Environment
        background
        near={1}
        far={1000}
        resolution={256}
        preset={env}
      />
    )}
    {dark_on ? <Background /> : null}
    {/* </Debug> */}
  </Physics>
);

const Dummy = () => (
  <Physics iterations={3} gravity={[0, -30, -4]}>
    {/* <BallAndCollisionsD /> */}
    <PaddleD />

    {
      Array.from({ length: 3 }, (_, i) => <MovingBlockD key={i} position={[0, i * 2, 0]} offset={10000 * i} />) /* prettier-ignore */
    }
    <BlockD
      args={[10, 1.5, 4]}
      position={[-11, -7, 0]}
      rotation={[0, 0, -0.7]}
      material={{ restitution: 1.2 }}
    />
    <BlockD
      args={[10, 1.5, 4]}
      position={[11, -7, 0]}
      rotation={[0, 0, 0.7]}
      material={{ restitution: 1.2 }}
    />
    {/* <Environment preset="warehouse" /> */}
    <Environment
      background
      near={1}
      far={1000}
      resolution={256}
      preset={environ}
    />
    {/* <Background position={[0, 0, -5]} /> */}
  </Physics>
);

// function App() {
export const App = () => {
  // const gameOver = useStore((s) => s.gameOver); // game over boolean
  const gameStarted = useStore((s) => s.gameStarted); // game over boolean

  const warehouse = useStore((s) => s.warehouse); // game over boolean
  const sounds = useStore((s) => s.sounds); // game over boolean
  const voice = useStore((s) => s.voice); // game over boolean
  const training = useStore((s) => s.training);
  const level = useStore((s) => s.level);

  training_on = training;

  level_num = level;

  if (warehouse) {
    dark_on = false;
  } else {
    dark_on = true;
  }
  if (sounds && gameStarted) {
    sound_on = true;
  } else {
    sound_on = false;
  }
  if (voice) {
    voice_on = true;
  } else {
    voice_on = false;
  }

  Params();

  return (
    <>
      <Canvas dpr={1.5} camera={{ position: [0, 2, 12], fov: 50 }}>
        <Suspense fallback={<GameLoader />}>
          {/* <Music /> */}
          {gameStarted ? <Game /> : <Dummy />}
          <Preload all />
        </Suspense>
      </Canvas>
      {gameStarted ? <Placard gameStarted={gameStarted} /> : <Dom />}
    </>
  );
};

// export default App;
