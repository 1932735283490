import { addEffect } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import "./styles/placard.css";
import { data } from "./zustand/zStore";
import fullscr_open from "./images/fullscreen_open.png";
import fullscr_exit from "./images/fullscreen_exit.png";

// interface HUD_Props {
//   gameStarted: boolean;
// }

const retrievePoints = () => data.points;
const retrieveGoals = () => data.goals;
const retrieveLevel = () => data.level;
const retrieveGoalsx = () => data.goalsx;

// var elem = document.getElementById("root");

// function openFullscreen() {
//   if (elem.requestFullscreen) {
//     elem.requestFullscreen();
//   } else if (elem.webkitRequestFullscreen) {
//     /* Safari */
//     elem.webkitRequestFullscreen();
//   } else if (elem.msRequestFullscreen) {
//     /* IE11 */
//     elem.msRequestFullscreen();
//   }
// }

export function Placard({ gameStarted }) {
  // export function HUD({ gameStarted }) {
  // const gameStarted = useStore((s) => s.gameStarted);
  const points_ref = useRef(null);
  const goals_ref = useRef(null);
  // const level_ref = useRef<HTMLDivElement>(null!);
  const level_ref = useRef(null);
  // const collisions_ref = useRef<HTMLDivElement>(null!);
  const goalsx_ref = useRef(null);

  let currentPoints = retrievePoints();
  let currentGoals = retrieveGoals();
  let currentLevel = retrieveLevel();
  let currentGoalsx = retrieveGoalsx();

  const [fullscr, setFullscr] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);

  document.addEventListener('fullscreenchange', (event) => {
    if (document.fullscreenElement) {
      // We’re going fullscreen
      console.log("Fullscreen on");
    } else {
      // We’re exiting fullscreen
      console.log("Fullscreen off");
    }
  });


  // Watch for fullscreenchange
  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  //addEffect from fiber -- Adds a global callback which is called each frame
  useEffect(() =>
    addEffect(() => {
      //UPDATES ARE BEING MADE ONLY WHEN CHANGES ARE BEING DETECTED
      if (points_ref.current && goals_ref.current && goalsx_ref.current) {
        points_ref.current.innerText !==
          retrievePoints().toFixed(0).toString() &&
          (points_ref.current.innerText = retrievePoints()
            .toFixed(0)
            .toString());

        goals_ref.current.innerText !== retrieveGoals().toString() &&
          (goals_ref.current.innerText = retrieveGoals().toFixed(0).toString());
        // .substr(0, 5)

        goalsx_ref.current.innerText !== retrieveGoalsx().toString() &&
          (goalsx_ref.current.innerText = retrieveGoalsx()
            .toFixed(0)
            .toString());
        // .substr(0, 5)
      }
    })
  );

  return (
    // <div className="row text-center">
    <div className={gameStarted ? "hud_container show" : "hud_container"}>
      <div className="current_goals">
        <span ref={goals_ref}>{currentGoals}</span>
      </div>

      <div className="current_points">
        <span ref={points_ref}>{currentPoints}</span>
      </div>

      {/* <div className="under_construction">
        Under Construction
      </div> */}

      <div>
        <button
          name="fc"
          className={"fullscr_button"}
          onClick={() => {
            if (!isFullscreen) {
              document.body.requestFullscreen();
            } else {
              document.exitFullscreen();
            }
          }}
        >
          <img
            src={!isFullscreen ? fullscr_open : fullscr_exit }
            height="30"
            size="30"
            title="fullscreen"
            alt="fullscreen"
          ></img>
        </button>
      </div>

      <div className="current_goals_x">
        <span ref={goalsx_ref}>{currentGoalsx}</span>
      </div>
    </div>
    // </div>
  );
}
