import {
  GAME_NAME,
  GAME_INFO,
  MAIN_MENU_BACKGROUND_COLOR,
} from "../app_config";
import React, { useEffect } from "react";
import "../styles/menu.css";
import logo from "../images/game_t.png";
import flag_pt from "../images/PT.png";
import flag_en from "../images/EN.png";
import { useStore, data } from "../zustand/zStore";

const text_pt = [
  "Com Som",
  "Com Voz",
  "Armázem como fundo",
  "PLAY",
  "RECOMEÇAR",
  "Instruções",
  "Treinar",
  "Nível",
];
const text_en = [
  "Sound On",
  "Voice On",
  "Warehouse background",
  "PLAY",
  "RESTART",
  "HowTo",
  "Training",
  "Level",
];

const Sounds_on = ({ sounds }) => {
  const setSounds = useStore((s) => s.setSounds);
  return (
    <input
      type="checkbox"
      defaultChecked
      onClick={() => setSounds(!sounds)}
    ></input>
  );
};

const Sounds_off = ({ sounds }) => {
  const setSounds = useStore((s) => s.setSounds);
  return <input type="checkbox" onClick={() => setSounds(!sounds)}></input>;
};

const Voice_on = ({ voice }) => {
  const setVoice = useStore((s) => s.setVoice);
  return (
    <input
      type="checkbox"
      defaultChecked
      onClick={() => setVoice(!voice)}
    ></input>
  );
};

const Voice_off = ({ voice }) => {
  const setVoice = useStore((s) => s.setVoice);
  return <input type="checkbox" onClick={() => setVoice(!voice)}></input>;
};

const Dark_backg = () => {
  const setWarehouse = useStore((s) => s.setWarehouse);
  return (
    <input
      type="checkbox"
      onClick={() => {
        const warehouse = true;
        setWarehouse(warehouse);
      }}
    ></input>
  );
};

const Warehouse_backg = () => {
  const setWarehouse = useStore((s) => s.setWarehouse);
  return (
    <input
      type="checkbox"
      defaultChecked
      onClick={() => {
        const warehouse = false;
        setWarehouse(warehouse);
      }}
    ></input>
  );
};

export function Menu({ gameStarted, gameLoaded }) {

  const gameOver = useStore((state) => state.gameOver);

  const avatar = useStore((state) => state.avatar);

  // const music = useStore((s) => s.music);
  // const setMusic = useStore((s) => s.setMusic);

  const setGameStarted = useStore((s) => s.setGameStarted);

  const setGameOver = useStore((s) => s.setGameOver);

  const showMenu = useStore((s) => s.menu);
  const setMenu = useStore((s) => s.setMenu);

  const showInfo = useStore((s) => s.info);
  const setInfo = useStore((s) => s.setInfo);

  const showLinks = useStore((s) => s.links);
  const setLinks = useStore((s) => s.setLinks);

  const showHowTo = useStore((s) => s.howto);
  const setHowTo = useStore((s) => s.setHowTo);

  const sounds = useStore((s) => s.sounds);
  const voice = useStore((s) => s.voice);
  const warehouse = useStore((s) => s.warehouse);

  // const setSounds = useStore((s) => s.setSounds);

  const training = useStore((s) => s.training);
  const setTraining = useStore((s) => s.setTraining);

  let level = useStore((s) => s.level);
  const setLevel = useStore((s) => s.setLevel);

  let lang = useStore((s) => s.lang);
  const setLang = useStore((s) => s.setLang);

  let FLAG = "";
  if (lang === "EN") {
    FLAG = flag_pt;
  } else {
    FLAG = flag_en;
  }

  const handleChange = (event) => {
    setLevel(event.target.value);
  };

  return (
    <>
      <div
        className={gameLoaded && !gameStarted ? `main_menu show ` : "main_menu"}
        style={{
          background: MAIN_MENU_BACKGROUND_COLOR,
        }}
      >
        <div
          className={
            gameLoaded && !gameStarted
              ? `column_container`
              : "column_container block_all"
          }
        >
          <div className="name_game ">{GAME_NAME}</div>
          <div className="name_pinball ">{GAME_INFO}</div>
          <img alt="logo for the game" className="logo " src={logo} />
          {gameOver ? (
            <button
              onClick={() => {
                setGameStarted(true);
                avatar.current.position.set(0, 0, 0);
                // data.gameSpeed = GAME_SPEED_START;
                data.level = 1;
                // camera.current.rotation.y = 0;
                setGameOver(false);
              }}
              className={"play_button"}
            >
              {lang === "PT" ? text_pt.slice(4, 5) : text_en.slice(4, 5)}
              {/* RESTART */}
            </button>
          ) : (
            <button
              onClick={() => {
                setGameStarted(true);
                // setSounds(true);
                data.goals = 0;
                data.goalsx = 0;
                data.points = 0;
                // data.gameSpeed = GAME_SPEED_START;
                // camera.current.rotation.y = 0;
              }}
              className={"play_button"}
            >
              {lang === "PT" ? text_pt.slice(3, 4) : text_en.slice(3, 4)}
              {/* PLAY */}
            </button>
          )}

          <label className={"options_label"}>
            {sounds === false ? (
              <Sounds_off sounds={sounds} />
            ) : (
              <Sounds_on sounds={sounds} />
            )}
            {lang === "PT" ? text_pt.slice(0, 1) : text_en.slice(0, 1)}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {voice === false ? (
              <Voice_off voice={voice} />
            ) : (
              <Voice_on voice={voice} />
            )}
            {lang === "PT" ? text_pt.slice(1, 2) : text_en.slice(1, 2)}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {lang === "PT" ? text_pt.slice(7, 8) : text_en.slice(7, 8)}
            &nbsp;
            <select
              name="level"
              defaultValue={level} onChange={handleChange}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </label>
          <label className={"options_label"}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {warehouse === false ? <Dark_backg /> : <Warehouse_backg />}
            {lang === "PT" ? text_pt.slice(2, 3) : text_en.slice(2, 3)}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {/* </label>
          <label className={"options_label"}> */}
            <input
              type="checkbox"
              onClick={() => {
                setTraining(!training);
              }}
            ></input>
            {lang === "PT" ? text_pt.slice(6, 7) : text_en.slice(6, 7)}
            &nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          <label className={"options_label"}>
            <button
              name="B1"
              className={"lang_button"}
              onClick={() => {
                {
                  lang === "PT" ? (lang = "EN") : (lang = "PT");
                }
                setLang(lang);
              }}
            >
              {/* PT */}
              <img src={FLAG} height="20" size="20" alt=""></img>
            </button>
            &nbsp;&nbsp;
            <button
              name="B2"
              className={"info_button"}
              onClick={() => {
                setHowTo(!showHowTo);
                setMenu(!showMenu);
              }}
            >
              {lang === "PT" ? text_pt.slice(5, 6) : text_en.slice(5, 6)}
            </button>
            &nbsp;&nbsp;
            <button
              name="B3"
              className={"info_button"}
              onClick={() => {
                setInfo(!showInfo);
                setMenu(!showMenu);
              }}
            >
              Info
              {/* {lang === "PT" ? back_pt.slice(0, 1) : back_en.slice(0, 1)} */}
            </button>
            &nbsp;&nbsp;
            <button
              name="B4"
              className={"info_button"}
              onClick={() => {
                setLinks(!showLinks);
                setMenu(!showMenu);
              }}
            >
              Links
            </button>
          </label>
        </div>
      </div>
    </>
  );
// };
}
